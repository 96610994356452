.main-container {
  display: flex;
  flex-direction: column;
  width: 100%;
  border: "2px solid red";
}
.head {
  font-size: 20px;
}
.leg-key {
  color: #1976d2;
  font-weight: bold;
}
.leg-value {
  font-weight: 400;
}

.leg-header {
  padding-bottom: 15px;
  border-bottom: 1px solid #ccc;
  margin-bottom: 15px;
}
.data {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  max-width: 1000px;
}
.body {
  display: flex;
  flex-direction: row;
  margin-bottom: 20px;
}
.key {
  color: "grey";
  min-width: 180px;
}
.value {
  color: "black";
}

.fields {
  margin-right: 15px;
}
