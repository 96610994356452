.form {
  padding: 10px;
  /* background-color: yellowgreen; */
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.container {
  padding: 20px;
  width: 300px;
  /* width: fit-content; */
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.Sub-container {
  display: flex;
  flex-direction: column;
  margin-right: 20px;
}
.detail {
  display: flex;
  flex-direction: row;
  padding: 5px;
  justify-content: space-between;
  align-items: center;
}

.heading {
  font-size: "16px";
  font-weight: "800";
  letter-spacing: "1.2px";
  color: "grey";
}

/* cha customs css */
.input_render {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
.key {
  font-size: "16px";
  font-weight: bold;
  letter-spacing: "0.5px";
  color: "black";
}
.value {
  font-size: "16px";
  color: "grey";
}
.pairBox {
  display: "flex";
  flex-direction: "row";
}
.lineborder {
  border-bottom: 1px dotted grey;
  padding: 10px;
}
