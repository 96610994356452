.dialog_container {
  padding: 25px;
}
.dialogue_btn {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
.actionBtn {
  display: flex;
  justify-content: flex-end;
  flex-direction: row;
  align-items: center;
}
.align {
  display: flex;
  /* flex-direction: row;
  justify-content: space-between;
  align-items: center;
  align-content: center; */
}
.btn {
  padding: 2px;
}

.dialogTitle {
  background-color: #00ae39 !important;
  padding: 2px 10px;
  text-align: "center";
  border-radius: 5px;
  display: inline-block;
}

.blueColor {
  background-color: #145291 !important;
}
.greyColor {
  background-color: #555 !important;
  color: #fff;
}
